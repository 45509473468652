<template>
  <div :class="$style.admin">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
.admin {
  width: 100%;
  padding: 16px;
}
</style>
